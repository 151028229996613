h4 .list-unstyled {
    display: flex;
    margin: 0;

    li {
        padding: 0 0.25rem;
        font-size: 14px;
    }
}

.hide {
    display:none;
}

.pointer_label {
    cursor: pointer;
}