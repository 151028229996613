html, body, #app {
    height: 100% !important;
}

body.transitioning,
body.transitioning * {
    transition: all 0.2s ease !important;
}

body {
    color: var(--theme-type-color);
}

.form-control,
.form-control:disabled,
.form-control:focus,
.custom-select,
.dropdown-menu {
    background-color: var(--theme-form-control-background);
    color: var(--theme-type-color);
    border-color: var(--theme-form-control-border);
}

.dropdown-item {
    color: var(--theme-type-color);

    &:hover {
        background-color: rgba(0, 0, 0, .2);
        color: var(--theme-type-hc-color);
    }
}

.dropdown-divider {
    border-color: rgba(0, 0, 0, .2) !important;
}

.table,
.table-hover tbody tr:hover {
    color: var(--theme-type-color);
}

.modal-content {
    background-color: var(--theme-card-background);
}

.btn-group-toggle {
    .btn.active {
        @extend .btn-primary;
    }
}

.card {
    @extend .shadow-sm;
    background: var(--theme-card-background);
}

.form-group > label {
    font-weight: 600;
}

.pagination {

    .page-item {

        &.active {

            .page-link {
                background-color: var(--theme-main);
            }
        }

        &.disabled {

            .page-link {
                background-color: rgba(0, 0, 0, .3);
            }
        }

        .page-link {
            background-color: var(--theme-card-background);
            border: 1px solid rgba(0, 0, 0, .2);
        }
    }
}

.table {
    --highlight-color: rgba(0, 0, 0, .3);

    thead,
    tfoot {
        color: var(--theme-type-hc-color) !important;

        th {
            border: none;
        }
    }

    thead.thead-dark th {
        background-color: lighten(#000, 10);
    }

    tr {

        td {
            border-color: var(--highlight-color);

            &.highlight {
                background-color: var(--highlight-color);
            }
        }

        &:hover {
            td {
                background-color: var(--highlight-color);
            }
        }
    }
}

.card-body {

    &.p-0 {
        .table:first-child {
            tr {
                th {

                    &:first-child {
                        border-top-left-radius: .3rem;
                    }

                    &:last-child {
                        border-top-right-radius: .3rem;
                    }
                }
            }
        }

        .table:last-child {
            margin: 0;
        }
    }
}