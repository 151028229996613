// Body
$body-bg: var(--theme-background);

// Typography
$font-family-sans-serif: 'Titillium', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$primary: #007499;
$primary: #5f7adb;

// Borders
$border-color: rgba(0, 0, 0, .3);

$card-bg: var(--theme-card-background);

:root {
    // Color Schemes
    --theme-main: #{$primary};
    --theme-dark-50: #{darken($primary, 5)};
    --theme-dark-100: #{darken($primary, 10)};

    --theme-background: #f8fafc;
    --theme-card-background: #fff;
    --theme-form-control-background: #fff;
    --theme-form-control-border: #{$border-color};

    --theme-type-color: #212529;
    --theme-type-hc-color: #000; // hc = high contrast

    &.dark-mode-enable {
        //--theme-background: #252D3A;
        --theme-background: #2e3239;
        //--theme-card-background: #182335;
        --theme-card-background: #26292b;
        //--theme-form-control-background: #23364e;
        --theme-form-control-background: #2e3239;
        //--theme-form-control-border: #000;

        //--theme-type-color: #aad5ff;
        --theme-type-color: #{lighten($primary, 35)};
        --theme-type-hc-color: #fff;
    }

    // Layout
    --navbar-height: 65px;
    --side-menu-closed-width: 0;
    --side-menu-open-width: 100%;

    // Sticky tables initial values
    --sticky-table-offset: 0;

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    :root {

        // Layout
        --side-menu-closed-width: 70px;
        --side-menu-open-width: 250px;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {}