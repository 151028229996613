// base icon class definition
// -------------------------

.#{$fa-css-prefix} {
  font-family: var(--#{$fa-css-prefix}-style-family, '#{$fa-style-family}');
  font-weight: var(--#{$fa-css-prefix}-style, #{$fa-style});
}

.fas,
.far,
.fab,
.#{$fa-css-prefix}-solid,
.#{$fa-css-prefix}-regular,
.#{$fa-css-prefix}-brands,
.#{$fa-css-prefix} {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--#{$fa-css-prefix}-display, #{$fa-display});
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
}

.fas::before,
.far::before,
.fab::before,
.#{$fa-css-prefix}-solid::before,
.#{$fa-css-prefix}-regular::before,
.#{$fa-css-prefix}-brands::before,
.fa::before {
  content: var(#{$fa-icon-property});
  content: var(#{$fa-icon-property}) / "";
}

.#{$fa-css-prefix}-classic,
.fas,
.#{$fa-css-prefix}-solid,
.far,
.#{$fa-css-prefix}-regular {
  font-family: 'Font Awesome 6 Free';
}
.#{$fa-css-prefix}-brands,
.fab {
  font-family: 'Font Awesome 6 Brands';
}

%fa-icon {
  @include fa-icon;
}
