#navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 0.5rem;
    background: var(--theme-main);
    background: linear-gradient(230deg, var(--theme-dark-50) 10%, var(--theme-main) 120%);
    height: var(--navbar-height);

    .nav-link {
        color: #fff;
    }

    .auth-users {

        a {
            display: inline-block;
            text-align: center;
            background: var(--success);
            color: #fff;
            font-size: 1.1rem;
            line-height: 38px;
            user-select: none;
            text-transform: capitalize;

            &.impersonated {
                background: var(--blue);
                margin-left: -22px;
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
        padding: 0.5rem 0;
    }
}

#side-menu-toggler {
    --size: 40px;
    --padding: 8px;
    width: var(--size);
    height: var(--size);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 16px 0 0;
    background-color: rgba(0, 0, 0, .2);
    border-radius: 6px;
    position: relative;
    padding: var(--padding);

    span {
        display: block;
        width: 100%;
        height: 2px;
        background: #fff;
        border-radius: 3px;
        transition: all .3s ease;
        position: absolute;
        left: var(--padding);
        transform: translate(0, 0) rotate(0);

        &:nth-child(1) {
            top: calc(3px + var(--padding));
            width: calc(100% - calc(2 * var(--padding)));
        }

        &:nth-child(2) {
            top: calc(50% - 1px);
            width: calc(85% - calc(2 * var(--padding)));
        }

        &:nth-child(3) {
            bottom: calc(3px + var(--padding));
            width: calc(65% - calc(2 * var(--padding)));
        }
    }

    &:not(.open):hover {
        span {
            &:nth-child(2) {
                width: calc(95% - calc(2 * var(--padding)));
            }

            &:nth-child(3) {
                width: calc(75% - calc(2 * var(--padding)));
            }
        }
    }

    &.open {
        span {
            width: 60%;

            &:nth-child(1) {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }

            &:nth-child(3) {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }
}