.custom-toggle {
    display: inline-block;
    height: 20px;

    input {
        display: none;

        &:checked ~ label {
            background: #38c172;

            &:after {
                left: 17px;
            }
        }
    }

    label {
        margin: 0;
        display: inline-block;
        border-radius: 500px;
        border: 1px solid #eee;
        width: 35px;
        height: 19px;
        background: #6c757d;
        position: relative;
        cursor: pointer;
        transition: all 0.2s ease;

        &:after {
            content: "";
            position: absolute;
            display: inline-block;
            height: 15px;
            width: 15px;
            border-radius: 100%;
            background: #fff;
            top: 1px;
            left: 1px;
            transition: all 0.2s ease;
        }
    }
}

.avatar {
    --size: 60px;
    border-radius: 100%;
    width: var(--size);
    height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
    max-width: var(--size);
    max-height: var(--size);
    overflow: hidden;
    @extend .border;

    &.avatar-xs {
        --size: 32px;
    }

    &.avatar-sm {
        --size: 40px;
    }

    &.avatar-lg {
        --size: 85px;
    }

    &.avatar-xl {
        --size: 150px;
    }
}

.dropdown-select-menu {
    width: 100%;
    transform: none !important;
    top: 100% !important;
    max-height: 300px;
    overflow: auto;

    .custom-control, label {
        cursor: pointer;
    }
}

.sticky-table-wrapper table {

    tr {
        th {
            position: sticky;
            top: var(--sticky-table-offset);
            transition: top .2s ease;
        }
    }
}

.responsive-table-wrapper {
    overflow-x: auto;

    .table {
        th, td {
            white-space: nowrap;
        }
    }
}