@mixin side-menu-open {
    width: var(--side-menu-open-width);

    .nav-item {

        .nav-link {

            > .icon {
                margin-right: 0.5rem;
            }
        }
    }
}

#app {

    &.menu-open {

        #side-menu {
            @include side-menu-open;
        }

        #main {
            padding-left: 0;

            // Large devices (desktops, 992px and up)
            @media (min-width: 992px) {
                padding-left: var(--side-menu-open-width);
            }
        }
    }

    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
        &.menu-open {
            overflow: hidden;
        }
    }
}

#main {
    padding-top: calc(1rem + var(--navbar-height));
    padding-left: var(--side-menu-closed-width);
    position: relative;
}

#side-menu {
    width: var(--side-menu-closed-width);
    padding-top: var(--navbar-height);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
    background: var(--theme-card-background, #fff);
    transition: all 0.2s ease;
    overflow-x: hidden;
    overflow-y: scroll;

    &:hover {
        @include side-menu-open;
    }

    .nav-item {

        .nav-link {
            padding: 12px 23px;
            color: var(--theme-type-color, #007499);
            white-space: nowrap;
            overflow: hidden;
            text-transform: capitalize;
            position: relative;

            > .icon {
                display: inline-block;
                height: 24px;
                width: 24px;
                fill: var(--theme-type-color, #007499);
                opacity: .6;
                margin-right: 2rem;
                transition: all 0.2s ease;
            }

            &:hover {
                color: var(--theme-type-hc-color, #007499);
                background: rgba(0, 0, 0, .3);
                > .icon {
                    fill: var(--theme-type-hc-color, #007499);
                    opacity: 1;
                }
            }

            &.active {
                background: var(--theme-dark-100, #252D3A);
                color: #fff;
                > .icon {
                    fill: #fff;
                    opacity: 1;
                }

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    border-radius: 2px;
                    border: 1px solid #fff;
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    top: calc(50% - 5px);
                    right: 10px;
                    transform: rotate(45deg);
                }

            }
        }
    }
}

#color-mode {

    &:not(.dark) {
        .icon.moon {
            display: none !important;
        }

        .icon.sun {
            display: inline-block;
        }
    }

    &.dark {
        .icon.moon {
           display: inline-block;
        }

        .icon.sun {
            display: none !important;
        }
    }
}