// Fonts
@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,300i,400,400i,700,700i&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap';

// Resetting some Bootstrap styles
@import 'reset';

// Navbar
@import 'navbar';

// Layout and general structure
@import 'layout';

// Custom Components
@import 'custom-components';

// Utility and helper classes
@import 'utilities';

@import './fontawesome/scss/fontawesome.scss';
@import './fontawesome/scss/solid.scss';
@import './fontawesome/scss/brands.scss';

// Page headers
.page-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 1.2rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, .1);

    .title {
        text-transform: uppercase;
        margin: 0;
    }

    .actions {
        display: flex;
        gap: 5px;
        flex-direction: column;

        .action {
            flex: 1;

            &.no-flex {
                flex: 0;
            }

            >.btn {
                min-width: max-content;
                width: 100%;
            }
        }
    }


    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {

        .actions {
            flex-direction: row;
        }

    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
        flex-direction: row;
        align-items: center;

    }
}

.simple-pagination {
    display: flex;
    justify-content: center;
}